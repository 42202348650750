<template>
  <div class="fs-container">
    <div class="fs-section fs-banner banner__club">
      <div class="fs-inr banner-inr">
        <div class="banner-slider swiper-container">
          <swiper
            :slides-per-view="1"
            :space-between="50"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
          >
            <swiper-slide v-for="b in BannerMain" :key="b.id">
              <a class="fs-pic" :href="b.Url"
                ><img
                  loading="lazy"
                  alt="Banner Mobile"
                  class="fs-sp"
                  :src="b.ThumbnailMobile"
              /></a>
              <a class="fs-pic desktop-img" :href="b.Url"
                ><img
                  loading="lazy"
                  alt="Banner Desktop"
                  class="fs-pc"
                  :src="b.Thumbnail"
              /></a>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
    <!-- Tabbar -->

    <div class="fs-section section__nav">
      <div class="fs-inr nav__inr">
        <div class="nav-news">
          <ul>
            <li>
              <a href="/club">
                <h2>ITEL ƯU ĐÃI THÀNH VIÊN</h2>
              </a>
            </li>
            <li href="javascript:void(0);" class="active">
              <a>
                <h2>ITEL CHÍNH SÁCH/ TIN TỨC ƯU ĐÃI</h2>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- News list -->
    <div class="fs-section news__promotion">
      <div class="fs-inr news-inr">
        <div class="category__list">
          <a
            v-for="categoryItem in listCategory"
            :class="categoryItem.Id == slectedCategory ? 'active' : ''"
            @click="setSlectedCategory(categoryItem.Id)"
            :key="categoryItem.Id"
            >{{ categoryItem.Name }}</a
          >
        </div>
        <div class="news-list">
          <a
            class="news-item"
            v-for="newsItem in listBlogLoyaltyByCategory"
            :key="newsItem.Id"
            :href="'/club-blog/gioi-thieu/' + newsItem.Slug"
            @click="selectedNews(newsItem.Slug)"
          >
            <div
              class="fs-bg cmBg"
              :style="{
                backgroundImage: `url(${newsItem.Thumbnail})`,
              }"
            ></div>
            <div class="fs-txt">
              <h3>{{ newsItem.Title }}</h3>
              <p>{{ newsItem.Brief }}</p>
            </div>
            <div class="user-box">
              <div class="user-pic">
                <img :src="newsItem.AuthorIcon" :alt="newsItem.Author" />
              </div>
              <div class="user-txt">{{ newsItem.Author }}</div>
            </div>
          </a>
        </div>

        <!-- <div
          class="has-text-centered"
          [hidden]="blogLoyaltyPaging.TotalPage <= 1"
        >
          <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
          <pagination-controls
            previousLabel="{{ 'Common.Previous page' | translate }}"
            maxSize="6"
            nextLabel="{{ 'Common.Next page' | translate }}"
            class="my-pagination"
            (pageChange)="getBlogLoyaltyItems($event)"
            id="server-blog-loyalty"
          ></pagination-controls>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { Navigation } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import { BCard, BRow, BCol, BContainer, BPagination } from "bootstrap-vue";
import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation]);

import { BannerMain, categories } from "../mockData/club";
import useJwt from "@/auth/jwt/useJwt";

export default {
  name: "ClubBlog",

  data() {
    return {
      BannerMain,
      listCategory: [],
      listBlogLoyaltyByCategory: [],
      slectedCategory: 2,
    };
  },

  mounted() {
    this.fetchBlogLoyaltyByCategory();
    this.fetchCategories();
  },

  methods: {
    selectedNews(slug) {
      console.log(slug);
    },

    setSlectedCategory(id) {
      this.slectedCategory = id;
    },

    async fetchBlogLoyaltyByCategory() {
      try {
        const res = await useJwt.post("/club/get-blogLoyalty-by-category1", {
          columnFilters: {
            categoryId: this.slectedCategory,
          },
          sort: [],
          page: 1,
          pageSize: 1000,
          lang: 1,
        });
        return (this.listBlogLoyaltyByCategory = res.data.result);
      } catch (error) {
        console.log("error", error);
      }
    },
    async fetchCategories() {
      try {
        const res = await useJwt.post("/club/categories", {
          columnFilters: {},
          sort: [],
          page: 1,
          pageSize: 1000,
          lang: 1,
        });
        return (this.listCategory = res.data.result);
      } catch (error) {}
    },
  },

  watch: {
    slectedCategory(val) {
      this.fetchBlogLoyaltyByCategory();
    },
  },

  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };

    const onSlideChange = () => {
      console.log("slide change");
    };

    return {
      onSwiper,
      onSlideChange,
    };
  },

  components: {
    BCard,
    BRow,
    BCol,
    BContainer,
    BPagination,
    Swiper,
    SwiperSlide,
  },
};
</script>

<style>
.fs-container {
  overflow: hidden;
}
.banner__club .swiper-pagination {
  width: 100%;
  left: 0;
  bottom: 4%;
}

.endow__box .swiper-button-prev {
  left: -6rem;
  margin-top: -1.5rem;
  background: url(../assets/images/prev_red.png) no-repeat;
  background-position: center center;
  background-size: 100% auto;
}
.endow__box .swiper-pagination {
  position: relative;
  padding: 1.6rem 0 0 0;
}
.endow__box .swiper-button-next {
  right: -6rem;
  margin-top: -1.5rem;
  background: url(../assets/images/next_red.png) no-repeat;
  background-position: center center;
  background-size: 100% auto;
}

.promotion__box .swiper-button-prev,
.promotion__box .swiper-button-next {
  top: -7rem;
}
.promotion__box .swiper-button-prev {
  left: auto;
  right: 5rem;
  background: url(../assets/images/prev_red.png) no-repeat;
  background-position: center center;
  background-size: 100% auto;
}
.promotion__box .swiper-button-next {
  right: 0;
  background: url(../assets/images/next_red.png) no-repeat;
  background-position: center center;
  background-size: 100% auto;
}
.promotion__box .swiper-pagination {
  position: relative;
  padding: 1.6rem 0 0 0;
}

.nav__inr,
.member__inr,
.discover__inr,
.voucher__inr,
.promotion__inr,
.endow__inr {
  max-width: 117rem;
}

.nav__inr {
  padding: 1.4rem 0 0 0;
}
.nav__inr .nav-news {
  margin: 0;
}

.section__member {
  padding: 2rem 0 0 0;
}
.member__inr {
  display: flex;
  align-items: center;
  padding: 3rem 0;
  box-shadow: 0 10px 30px rgba(209, 213, 223, 0.5);
  margin-bottom: 1.4rem;
}
.member__item {
  margin-left: 16%;
}
.member__item p {
  font-size: 2rem;
  color: #141821;
  font-weight: 700;
}
.member__item p.color__red {
  color: #ed1d24;
}
.has__start {
  padding-left: 4rem;
}
.start__red {
  background: url(../assets/images/club/start__red.png) no-repeat;
  background-position: left center;
  background-size: 3.5rem auto;
}
.start__silver {
  background: url(../assets/images/club/start__silver.png) no-repeat;
  background-position: left center;
  background-size: 3.5rem auto;
}
.start__yellow {
  background: url(../assets/images/club/start__yellow.png) no-repeat;
  background-position: left center;
  background-size: 3.5rem auto;
}
.start__diamond {
  background: url(../assets/images/club/start__diamond.png) no-repeat;
  background-position: left center;
  background-size: 3.5rem auto;
}

.voucher__box {
  position: relative;
}
.voucher__box .swiper-pagination {
  left: 30rem;
  bottom: 2.5rem;
}

.section__discover {
  padding: 2rem 0 0 0;
}
.discover__inr {
  padding: 0 15rem;
  display: flex;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(209, 213, 223, 0.5);
  margin-bottom: 2rem;
  align-items: center;
}
.discover__pic {
  flex: 0 0 18%;
  max-width: 18%;
  margin-right: 7%;
}
.discover__pic img {
  margin: -1.5rem 0;
}
.discover__txt {
  flex: 0 0 82%;
  max-width: 82%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.discover__detail {
  font-size: 2rem;
  color: #141821;
  font-weight: 700;
}
.discover__txt .login__but button {
  width: 15rem;
  padding: 0;
  -webkit-border-radius: 0.5rem;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  height: 5rem;
  text-transform: initial;
}

.section__voucher {
  padding-bottom: 5rem;
}

.endow__block {
  background-color: #ed1d24;
  padding: 3rem 10rem;
  -webkit-border-radius: 1rem;
  border-radius: 1rem;
  position: relative;
  margin-top: 25rem;
  top: -20rem;
  margin-bottom: -20rem;
}

.endow__title {
  font-size: 3.6rem;
  color: #fff;
  font-weight: 700;
  margin-bottom: 2rem;
}
.endow__title h1,
.endow__title h2,
.endow__title h3,
.endow__title h4,
.endow__title h5,
.endow__title h6 {
  text-transform: uppercase;
}
.endow__title p {
  font-size: 1.6rem;
  color: #fff;
  font-weight: 400;
}

.endow__block .swiper-slide {
  background-color: transparent;
}
.endor__item {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 2.8rem 1rem 2.8rem 3rem;
  margin-bottom: 2rem;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #fff;
  -webkit-border-radius: 1rem;
  border-radius: 1rem;
}
.endor__item:last-child {
  margin-bottom: 0;
}
.endor__item::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 86%;
  left: 27%;
  top: 7%;
  border-left: 1px dashed #d9d9d7;
}
.endor__bg {
  flex: 0 0 18%;
  max-width: 18%;
  margin-right: 8%;
  border-radius: 1rem;
}
.endor__bg::before {
  padding-top: 100%;
}
.endor__txt {
  flex: 0 0 54%;
  max-width: 54%;
}
.endor__txt h3 {
  font-size: 2rem;
  color: #141821;
  font-weight: 700;
  margin-bottom: 1.2rem;
}
.endor__date {
  padding-left: 2.4rem;
  background: url(../assets/images/club/icon_date.png) no-repeat;
  background-position: left center;
  color: #999999;
  font-weight: 500;
  font-size: 1.6rem;
  background-size: 1.6rem auto;
}
.endor__value {
  flex: 0 0 16%;
  max-width: 16%;
  font-size: 2rem;
  font-weight: 400;
  color: #fff;
  padding: 2rem 1rem;
  -webkit-border-radius: 1rem;
  border-radius: 1rem;
  background-color: #6adcd9;
  text-align: center;
}

.endow__box {
  position: relative;
}

.section__promotion {
  background-color: #f5f5f5;
}

.promotion__top {
  display: flex;
  flex-wrap: wrap;
  padding: 5rem 0 4.4rem 0;
  justify-content: space-between;
}

.promotion__title {
  font-size: 3.6rem;
  color: #393e46;
  font-weight: 700;
}
.promotion__title h1,
.promotion__title h2,
.promotion__title h3,
.promotion__title h4,
.promotion__title h5,
.promotion__title h6 {
  text-transform: uppercase;
}

.promotion__title p {
  font-size: 1.6rem;
  color: #6f7885;
  font-weight: 400;
}

.promotion__sort {
  display: flex;
}

.sort__col {
  width: 18rem;
  margin-left: 3.5%;
}

.promotion__list {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 3rem;
}

.promotion__item {
  position: relative;
  flex: 0 0 32%;
  max-width: 32%;
  margin: 0 2% 2% 0;
  -webkit-border-radius: 1rem;
  border-radius: 1rem;
  overflow: hidden;
  background-color: #fff;
  padding-bottom: 3rem;
  box-shadow: 0 10px 30px rgba(209, 213, 223, 0.5);
}
.promotion__item:nth-child(3n) {
  margin: 0 0 2% 0;
}
.promotion__bg {
  margin-bottom: 2.2rem;
}
.promotion__bg::before {
  padding-top: 100%;
}
.promotion__money {
  font-size: 2rem;
  color: #fff;
  font-weight: 700;
  position: absolute;
  top: 1.7rem;
  right: 1rem;
  background-color: #ec1d22;
  -webkit-border-radius: 1rem;
  border-radius: 1rem;
  padding: 1rem;
  z-index: 1;
}

.promotion__txt,
.core__block {
  padding: 0 3rem;
}
.promotion__txt {
  margin-bottom: 2.2rem;
}
.promotion__txt h3 {
  font-size: 2rem;
  color: #141821;
  font-weight: 700;
  min-height: 6rem;
}
.core__block {
  display: flex;
  flex-wrap: wrap;
}
.core__bg {
  flex: 0 0 27%;
  max-width: 27%;
  margin-right: 8%;
  -webkit-border-radius: 1rem;
  border-radius: 1rem;
}
.core__bg::before {
  padding-top: 100%;
}
.core__value,
.core__date {
  font-size: 1.6rem;
  font-weight: 500;
}
.core__value {
  background: url(../assets/images/club/icon_core.png) no-repeat;
  color: #ec1d22;
  margin-bottom: 1rem;
  padding-left: 2.4rem;
  background-size: 1.6rem auto;
  background-position: left center;
}
.core__date {
  padding-left: 2.4rem;
  background: url(../assets/images/club/icon_date.png) no-repeat;
  color: #999999;
  background-size: 1.6rem auto;
  background-position: left center;
}

.news__promotion {
  background-color: #f5f5f5;
  padding: 0 0 0 0;
}

.category__list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4.6rem 0;
}
.category__list a {
  padding: 1.5rem 3.5rem;
  font-size: 1.6rem;
  color: #393e46;
  font-weight: 700;
  background-color: rgba(255, 255, 255, 0);
  -webkit-border-radius: 3rem;
  border-radius: 3rem;
  margin: 0 3.2rem;
  cursor: pointer;
}
.category__list a.active {
  background-color: rgba(255, 255, 255, 1);
  color: #393e46;
}

.voucher__detail {
  padding: 4rem 0 0 0;
  margin-bottom: -9.4rem;
  z-index: 2;
}

.promotion__detail {
  background-color: #f5f5f5;
  padding: 11rem 0 6.6rem 0;
}
.promotion__detail__inr {
  max-width: 117rem;
}
.promotion__detail__box {
  display: flex;
  flex-wrap: wrap;
  padding: 3.8rem 3rem;
  background-color: #fff;
  box-shadow: 0 10px 30px rgba(209, 213, 223, 0.5);
  font-size: 1.6rem;
  color: #333333;
  font-weight: 400;
  line-height: 1.6;
}
.promotion__detail__box .promotion__item {
  box-shadow: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  flex: 0 0 67%;
  max-width: 67%;
  margin: 0 3% 0 0;
}
.promotion__detail__box .core__block {
  padding: 0 2.4%;
  position: relative;
  z-index: 1;
  margin-top: -4.5rem;
  align-items: flex-end;
}
.promotion__detail__box .core__bg {
  flex: 0 0 18%;
  max-width: 18%;
  margin-right: 2.4%;
  border: 1rem solid #fff;
}
.promotion__detail__box .core__txt {
  flex: 0 0 79.6%;
  max-width: 79.6%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: 3rem;
}
.core__txt h3 {
  width: 100%;
  font-size: 2.4rem;
  color: #141821;
  font-weight: 700;
  margin-bottom: 1.4rem;
}
.promotion__detail__box .core__value,
.promotion__detail__box .core__date {
  flex: 0 0 20%;
  max-width: 20%;
  margin: 0;
}

.promotion__detail__box h4 {
  position: relative;
  font-weight: 700;
  font-size: 1.6rem;
  padding-bottom: 1.6rem;
}
.tab__condition h4 {
  margin-bottom: 2rem;
  border-bottom: 1px solid #e5e5e5;
}
.tab__condition h4::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50%;
  height: 2px;
  background-color: #ed1f24;
}

.tab__condition {
  flex: 0 0 30%;
  max-width: 30%;
}
.condition__list {
  padding: 1rem 2rem 2rem 2rem;
  max-height: 37rem;
  overflow: hidden;
  overflow-y: auto;
}
.condition__list ul {
  padding: 0.7rem 0;
}
.condition__list li {
  padding-left: 4rem;
  font-size: 1.4rem;
  margin-bottom: 3rem;
  line-height: 1.6;
  background: url(../assets/images/club/check.png) no-repeat;
  background-size: 2.4rem auto;
  background-position: left 3px;
}

.tab__info {
  flex: 0 0 67%;
  max-width: 67%;
  padding: 2.5rem 0;
  font-size: 1.4rem;
}
.tab__condition .fs-buts {
  padding-top: 1rem;
}
.tab__condition .fs-but {
  font-size: 1.6rem;
  width: 100%;
  padding: 1.2rem 0;
  -webkit-border-radius: 1rem;
  border-radius: 1rem;
}

.promotion__box {
  position: relative;
}
.promotion__slider .promotion__item {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  display: block;
}
.promotion__slider .promotion__money {
  font-size: 1.4rem;
  font-weight: normal;
}

@media screen and (max-width: 1100px) {
  .endow__box .swiper-button-prev,
  .endow__box .swiper-button-next,
  .promotion__box .swiper-button-prev,
  .promotion__box .swiper-button-next {
    width: 7.2vw;
  }
  .endow__box .swiper-button-prev,
  .endow__box .swiper-button-next {
    top: 1vw;
    z-index: 11;
  }
  .promotion__box .swiper-button-prev,
  .promotion__box .swiper-button-next {
    top: 0;
    z-index: 11;
  }
  .endow__box .swiper-button-prev,
  .promotion__box .swiper-button-prev {
    left: 0;
    right: auto;
  }
  .endow__box .swiper-button-next,
  .promotion__box .swiper-button-next {
    right: 0;
    left: auto;
  }
  .endow__box .swiper-pagination,
  .promotion__box .swiper-pagination {
    position: relative;
    width: 100%;
    padding: 0 0 4vw 0;
  }

  .member__inr,
  .discover__inr,
  .voucher__inr,
  .endow__inr {
    width: 94%;
  }
  .promotion__inr {
    width: 100%;
  }

  .nav__inr {
    width: 100%;
    padding: 7vw 0 0 0;
  }

  .section__discover {
    padding: 4.26666vw 0 0 0;
  }
  .discover__inr {
    padding: 1.7777vw 3.5vw 0 3.5vw;
    margin-bottom: 8vw;
  }
  .discover__pic {
    flex: 0 0 32%;
    max-width: 32%;
    margin-right: 4%;
  }
  .discover__pic img {
    margin: 0;
  }
  .discover__txt {
    flex: 0 0 64%;
    max-width: 64%;
    flex-wrap: wrap;
  }
  .discover__detail {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    font-size: 3.5555vw;
    margin-bottom: 2.6666vw;
  }
  .discover__txt .fs-buts {
    flex: 0 0 100%;
    text-align: center;
    max-width: 100%;
  }
  .discover__txt .login__but button {
    font-size: 2.8444vw;
    height: auto;
    width: auto;
    padding: 2.2vw 5vw;
  }

  .section__member {
    padding: 5.3333vw 0 0 0;
  }
  .member__inr {
    flex-wrap: wrap;
    padding: 3.2vw 8vw;
    justify-content: space-between;
    margin-bottom: 4vw;
  }
  .member__item {
    margin: 0;
  }
  .member__item:nth-child(1) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 3.2vw;
  }
  .member__item p {
    font-size: 2.84444vw;
  }
  .member__item p.member_name {
    font-weight: 400;
  }
  .has__start {
    padding-left: 7vw;
  }
  .start__red,
  .start__silver,
  .start__yellow,
  .start__diamond {
    background-size: 6vw auto;
  }

  .endow__box {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .endow__block {
    margin: 0;
    top: auto;
    -webkit-border-radius: 0;
    border-radius: 0;
    padding: 4.62222vw 3%;
  }
  .endor__item {
    padding: 6vw 3% 6vw 6%;
    margin-bottom: 2.222vw;
  }
  .endor__txt h3 {
    font-size: 3.911vw;
    margin-bottom: 2.666vw;
  }
  .endor__date {
    padding-left: 4.8vw;
    background-size: 3.37777vw auto;
    font-size: 3.2vw;
  }
  .endor__value {
    padding: 3.5vw 3.1vw;
    font-size: 3.911vw;
  }

  .endow__title {
    font-size: 7.111vw;
    margin-bottom: 8vw;
  }
  .endow__title p {
    font-size: 2.8444vw;
  }

  .promotion__top {
    padding: 5.3333vw 3%;
    flex-wrap: wrap;
  }
  .promotion__title {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .promotion__sort {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: space-between;
  }
  .sort__col {
    width: 31%;
    margin: 0;
  }

  .promotion__title {
    font-size: 7.111vw;
    margin-bottom: 0;
  }
  .promotion__title p {
    font-size: 2.8444vw;
  }

  .promotion__box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .promotion__list {
    padding: 0 3%;
  }
  .promotion__item {
    padding-bottom: 3.37777vw;
  }
  .promotion__item,
  .promotion__item:nth-child(3n) {
    flex: 0 0 48.5%;
    max-width: 48.5%;
    margin: 0 3% 3% 0;
  }
  .promotion__item:nth-child(2n) {
    margin: 0 0 3% 0;
  }
  .promotion__bg {
    margin-bottom: 3.37777vw;
  }
  .promotion__txt,
  .core__block {
    padding: 0 5%;
    margin: 0;
  }
  .promotion__txt h3 {
    font-size: 3.2vw;
    margin-bottom: 3vw;
    min-height: 9.5vw;
  }
  .core__txt {
    padding-top: 1vw;
  }
  .core__value,
  .core__date {
    font-size: 2.84444vw;
    background-size: 2.2vw auto;
    padding-left: 3.2vw;
  }
  .promotion__money {
    right: 1.3333vw;
    top: 1.3333vw;
    font-size: 4.26666vw;
    padding: 1.6666vw 3vw;
  }

  .category__list {
    width: 94%;
    margin: 0 auto;
    padding: 5.333vw 0;
  }
  .category__list a {
    font-size: 2.7vw;
    padding: 2.2vw 4.4vw;
    margin: 0 1.5vw;
  }

  .promotion__detail__inr {
    width: 94%;
  }

  .promotion__detail {
    background-color: #fff;
    padding: 11.5vw 0;
  }
  .promotion__detail__box {
    padding: 2.6666vw;
  }
  .promotion__detail__box .promotion__item {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0 0 7vw 0;
  }
  .tab__condition,
  .tab__info {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .promotion__detail__box .core__block {
    margin-top: -6vw;
    padding: 0 0 0 4%;
  }
  .promotion__detail__box .core__bg {
    flex: 0 0 24%;
    max-width: 24%;
    border: 0.88vw solid #fff;
    margin-right: 4%;
  }
  .promotion__detail__box .core__txt {
    flex: 0 0 100%;
    max-width: 100%;
    top: auto;
    margin-top: 4.88vw;
  }
  .promotion__detail__box .core__txt h3 {
    font-size: 4.8vw;
    margin-bottom: 1.4vw;
  }
  .promotion__detail__box .core__value,
  .promotion__detail__box .core__date {
    font-size: 2.84444vw;
  }

  .tab__box {
    font-size: 3.375vw;
    line-height: 1.6;
    display: none;
  }
  .tab__box.active {
    display: block;
  }
  .tab__box p {
    margin-bottom: 3vw;
  }

  .tab__box h4 {
    display: none;
  }

  .condition__list {
    padding: 3.2vw 1.777vw;
    max-height: initial;
  }
  .condition__list li {
    background-size: 5.3333vw auto;
    background-position: left 1vw;
    font-size: 3.375vw;
    margin-bottom: 1.2vw;
    padding-left: 9.06vw;
    padding: 1vw 0 1vw 9.06vw;
  }

  .tab__header {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #e5e5e5;
    width: 100%;
    flex: 0 0 100%;
  }
  .tab__item {
    position: relative;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 5% 3vw 5%;
    font-size: 3.375vw;
    font-weight: 700;
    text-transform: uppercase;
    color: #adadad;
  }
  .tab__item.active {
    color: #393e46;
  }
  .tab__item::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #ed1f24;
    opacity: 0;
  }
  .tab__item.active::after {
    opacity: 1;
  }

  .promotion__detail__box .core__block {
    justify-content: space-between;
  }
  .promotion__detail__box .fs-buts {
    flex: 0 0 46%;
    max-height: 46%;
  }
  .promotion__detail__box .fs-but {
    padding: 0;
    font-size: 3.2vw;
    width: 100%;
    height: 10vw;
    -webkit-border-radius: 0;
    border-radius: 0;
  }

  .nav-news ul li a {
    padding: 2.8vw 2.8vw;
    font-size: 2.8vw;
  }
  .nav-news ul {
    padding: 0 1.8%;
  }
}

@media screen and (max-width: 520px) {
  .nav__inr {
    padding: 0 0 0 0;
  }
  .nav__inr .nav-news {
    margin: 0;
  }
  .nav-news ul {
    padding: 0 0 0 0;
  }

  .nav-news ul li a {
    padding: 1.2vw 1.2vw;
    font-size: 2.8vw;
  }

  .nav-news ul li h2 {
    font-size: 1.1rem;
    margin-left: 0;
    padding: 1vw 0 1vw 0;
  }
}
</style>